import React, {Component} from "react"
import Navbar from 'react-bootstrap/Navbar'
import Axios from "axios";

class Order extends Component{
    constructor(props) {
        super(props);
        this.state = { items: '' ,};
    }
    componentDidMount(){
       
        try{
            Axios.get("https://ipa.andamanwaveferry.com/v1/showOrderItem/"+this.props.voucher).then(
            res =>  {
                //console.log(res.data.data)
                this.setState({ items :  res.data.data})
            }) 
        }catch{
            this.setState({ items :  ""})
        }
       
    }

    showItems(items){
        //console.log(items)
        if(!items || items.length === 0){
            return ""
        }else{
            return this.state.items && this.state.items.map((item,index) =>{
                return(
                    <li key={index} className="text-right text-info my-2">
                        {item.productName}
                       
                    </li>  
                )
               
            })
        }
           
        
    }

    showOrders(orders){
        //console.log(orders)
        if(!orders || orders.length === 0){
            return <li className="text-right text-muted">Not Order </li>
        }else{
            return orders.map(order =>{
                return(
                    <li key={order.product.productNo} className="text-right text-success my-2">
                        {order.product.productName}
                        { <button className="btn btn-danger btn-sm mx-2" onClick={()=>this.props.onDelOrder(order.product)}>x</button> }
                    </li>  
                )
               
            })
        }
    }

    render(){
        const {orders,day,voucher,msg} = this.props
        //console.log(this.props)
        return(
            <div>
                { msg &&
                    <Navbar bg="primary" style={{color:"#fff"}}> {msg}</Navbar>
                }

                <h1 className="text-right">Voucher No. <samp className="text-success">{voucher}</samp></h1>
                <h2 className="text-right">Travel Date <samp className="text-success">{day}</samp> </h2>
                <hr />  
                <ul className="list-unstyled" >
                    
                    {this.showOrders(orders)}
                </ul>
                <hr />
                <button className="btn btn-block btn-success" onClick={()=>this.props.onConfirmOrder()}>save</button>
                {/* <button className="btn btn-block btn-danger" onClick={()=>this.props.onCancelOrder()}>cancel</button> */}
            </div>
        )
    }
        
}

export default Order
