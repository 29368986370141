import React, {Component} from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "../../src/test.css"

class Voucher extends Component{
    constructor(props) {
        super();
        this.state = {startDate : moment(new Date()).format("YYYY-MM-DD")};
      }
      handleChange = date => {
        this.setState({
          startDate: moment(date).format("YYYY-MM-DD")
        });
      };

      

    render(){
        const {onNumVoucher, onCheckVoucher , voucher ,resultCode , message} = this.props
       
        //console.log(this.props)
        return(
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col-md-4"></div>
                        <div className="col-md-4 my-5">
                           
                            <h2> Reservation ID. </h2>
                            <input className="form-control my-5" type="text" name="voucher"  onChange={onNumVoucher} placeholder="Reservation ID"/>
                            <h2> Travel Date. </h2>
                            <DatePicker name="travelDate" className="form-control"  dateFormat="YYYY-MM-DD" selected="" onChange={this.handleChange} value={this.state.startDate} />

                            <button className="btn btn-block btn-success my-5" value={voucher} onClick={(e)=>onCheckVoucher(e.target.value,this.state.startDate)}>Submit</button>            
                            { resultCode &&   
                                (<h2 className="text-danger my-5">{message}</h2>)            
                            }
                        </div>
                    <div className="col-md-4"></div>
                </div>
            </div>
 
        )
    }
}

export default Voucher

