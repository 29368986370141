import React, {Component} from "react"
import Navbar from 'react-bootstrap/Navbar'
import Axios from "axios";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
class Order extends Component{
    constructor(props) {
        super(props);
        this.state = { items: '' ,showDel:'', itemDel:''};
    }
    componentDidMount(){
       
        Axios.get("https://ipa.andamanwaveferry.com/v1/showOrderItem/"+this.props.voucher).then(
            res =>  {
                //console.log(res.data.data)
                this.setState({ items :  res.data.data})
            })
    }

   
    checkStatus(status,item){
        console.log(item)
        this.setState({showDel:''})
        if(status === 2){
            const value = {itemId : item}
            Axios.post("https://ipa.andamanwaveferry.com/v1/delItem/",value).then(
                res => {
                    //console.log(res.data)
                    //this.setState({start : res.data , startDate: today})
                        Axios.get("https://ipa.andamanwaveferry.com/v1/showOrderItem/"+this.props.voucher).then(
                        res =>  {
                            //console.log(res.data.data)
                            this.setState({ items :  res.data.data})
                        })
                    }
                )

        }
    }

    itemDel(item){
        const value = item
        this.setState({itemDel: value,showDel:'show'})
    }

    showItems(items){
        //console.log(items)
       
        if(!items || items.length === 0){
            return ""
        }else{
            return this.state.items && this.state.items.map((item,index) =>{
                return(
                    <div key={index}>
                    <li  className="text-right text-info my-2">
                        {item.productName}
                        <button className="btn btn-danger btn-sm" onClick={()=>this.itemDel(item.productId)}>x</button>
                     

                    </li>  
                    <Modal key={index} show={this.state.showDel}>
                    <Modal.Header >
                      <Modal.Title>Delete Item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{item.productName}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger"  onClick={()=>this.checkStatus(1,item.productId)}>
                        Close
                      </Button>
                      <Button variant="success" onClick={()=>this.checkStatus(2,item.productId)}>
                        Submit
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  </div>
                )
               
            })
        }
           
        
    }
    showOrders(orders){
        //console.log(orders)
        if(!orders || orders.length === 0){
            return <li className="text-right text-muted">Not Order </li>
        }else{
            return orders.map(order =>{
                return(
                    <li key={order.product.productNo} className="text-right text-success my-2">
                        {order.product.productName}
                        <button className="btn btn-light btn-sm" onClick={()=>this.props.onDelOrder(order.product)}>x</button>
                    </li>  
                )
               
            })
        }
    }

    render(){
        const {orders,day,voucher,msg} = this.props
        //console.log(this.props)
        return(
            <div>
                { msg &&
                    <Navbar bg="primary" style={{color:"#fff"}}> {msg}</Navbar>
                }

                <h1 className="text-right">Voucher No. <samp className="text-success">{voucher}</samp></h1>
                <h2 className="text-right">Travel Date <samp className="text-success">{day}</samp> </h2>
                <hr />  
                <ul className="list-unstyled" >
                    {this.showItems(this.state.items)}
                </ul>
                <hr />  
                <ul className="list-unstyled" >
                    {this.showOrders(orders)}
                </ul>
                <hr />
                <button className="btn btn-block btn-success" onClick={()=>this.props.onConfirmOrder()}>save</button>
                {/* <button className="btn btn-block btn-danger" onClick={()=>this.props.onCancelOrder()}>cancel</button> */}
            </div>
        )
    }
        
}

export default Order
