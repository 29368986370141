import React, {Component} from "react";
import Axios from "axios";
import Voucher from "./Voucher"
import Product from "./Product"

class Home extends Component{

    constructor(props) {
        super(props);
        this.state = { voucher: '' , start:'',products : '',cat: 1, startDate:""};
    }
    
    componentDidMount(){
        //var postData = {};
          
        //   let axiosConfig = {
        //     headers: {
        //         'Content-Type': 'application/json;charset=UTF-8',
        //         'appKey': '12335',
        //     }
        //   };
        
        
    }

    numVoucher = (event) =>{
        this.setState({voucher: event.target.value});
       
    }

    switchCat = (event) =>{
        //console.log(event)
        this.setState({cat: event});
        Axios.get("https://ipa.andamanwaveferry.com/v1/pass/getOrderItem/"+event+"/1").then(
            res =>  {
                //console.log(res.data)
                this.setState({ products :  res.data.data})
            })
    }

    checkVoucher = (e,today) => {
        const value = {voucher : e}
        //console.log(value)
        //console.log(today)
       
          
        // let axiosConfig = {
        //     headers: {
        //         'Content-Type': 'application/json;charset=UTF-8'
        //     }
        //   };
        Axios.post("https://ipa.andamanwaveferry.com/v1/checkVoucher",value).then(
            res => {
                //console.log(res.data)
                this.setState({start : res.data , startDate: today})
                }
            )

            Axios.get("https://ipa.andamanwaveferry.com/v1/pass/getOrderItem/"+this.state.cat+"/1/"+e).then(
                res =>  {
                    //console.log(res.data)
                    this.setState({ products :  res.data.data})
                })    
    }  

    render(){
        const {resultCode,message,type} = this.state.start
        return(
            <div>
                { resultCode !== 200000 &&
                    (
                    <Voucher onNumVoucher ={this.numVoucher} onCheckVoucher ={this.checkVoucher} voucher = {this.state.voucher} resultCode = {resultCode} message={message}/>
                    )
                }
                { resultCode === 200000 &&
                    (
                    <Product voucher = {this.state.voucher} products = {this.state.products} cat = {this.state.cat} onSwitchCat = {this.switchCat} today = {this.state.startDate} type={type}/>
                    )
                }
            </div>
        )
    }
}



export default Home;