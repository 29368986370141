import React , {Component} from "react";


class showProduct extends Component{
    constructor(props){
        super(props);
        this.state = {allocs : "" };
    }

    render(){
            const { productName,time,time_end,onAddOrder,area,areaId,type,status,boat_id,destinationId,destination } = this.props
           // console.log(this.props)
        if(area === "select" || parseInt(area) === parseInt(areaId)){
            if(type === "NO"){  
                if(destination === "select" || parseInt(destination) === parseInt(destinationId)){ 
            return(
            <tbody>
             <tr>
               
                <td>{productName}</td>
                <td>{time}</td> 
                <td>{time_end}</td>
                <td>
                {(status === 0) &&
                               <button className="btn btn-block btn-info" onClick={()=>onAddOrder(this.props)}>Cart</button>
                             
                            }
                            {(status === 1) &&
                               <button className="btn btn-block btn-info" onClick={()=>onAddOrder(this.props)} disabled>Cart</button>
                             
                            }
                </td>

             </tr>
             </tbody>

            ); 
            }else{
                return null;
            }
            }else{
                if(boat_id === 14){
                    if(destination === "select" || parseInt(destination) === parseInt(destinationId)){
                    return(
                        <tbody>
                         <tr>
                           
                            <td>{productName}</td>
                            <td>{time}</td> 
                            <td>{time_end}</td>
                            <td>
                            {(status === 0) &&
                               <button className="btn btn-block btn-info" onClick={()=>onAddOrder(this.props)}>Cart</button>
                             
                            }
                            {(status === 1) &&
                               <button className="btn btn-block btn-info" onClick={()=>onAddOrder(this.props)} disabled>Cart</button>
                             
                            }
                           
                            </td>
            
                         </tr>
                         </tbody>
            
                        ); 
                    }else{
                        return null;
                    }
                }else{
                    return null;
                }
            }
        }else{
            return null;
        }
           
        }
    }

export default showProduct;