import React, {Component} from "react";
import Table from 'react-bootstrap/Table';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ShowItem from './ShowProducts';
import OrderItem from './Order';
import "react-datepicker/dist/react-datepicker.css";
import Axios from "axios";

class Product extends Component {
    constructor(props){
        super(props);
        this.state = {area : "select",orders : [], msg:"",status:0 , destination:"select"};
        this.delOrder = this.delOrder.bind(this);
        this.addOrder = this.addOrder.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.confirmOrder = this.confirmOrder.bind(this);
    }
    
      
      switchArea = (event) =>{  
        this.setState({area: event});
        if(event !== "18"){
            this.setState({destination: "select"});
        }
        //console.log(event);
    }

    switchDes = (event) =>{  
        this.setState({destination: event});
        //console.log(event);
    }

    addOrder = (product) =>{
        //วนเช็คค่าใน order
          let findOrder = this.state.orders.find(order => order.product.productNo === product.productNo)
       
            if(findOrder){
                findOrder++;
            }else{
                this.state.orders.push({product: product, itemId : product.productNo, itemName:product.productName, startDate:product.today , voucher: this.props.voucher})
            }
            this.setState({orders: this.state.orders,msg:"",status:1})  

        //console.log(totalPrice) 
        
    }

    delOrder = (product) =>{
        //productID 
        let resultOrder = this.state.orders.filter(order => order.product.productNo !== product.productNo)
        this.setState({orders:resultOrder,status:0})
    }

    cancelOrder = () =>{
        this.setState({orders: [],msg:"",status:0})
    }

    confirmOrder = () =>{
        const {orders} = this.state
        //console.log(orders) 
        //const {totalPrice, orders} = this.state
        Axios.post("https://ipa.andamanwaveferry.com/v1/getOrderItem/user/",{orders})
             .then(res => {
                console.log(res.data) 
                this.setState({orders: [], msg: res.data.message});
             })
    }

    showProducts(today,type){
        if(this.props.products){

            return  this.props.products && this.props.products.map((product,index ) => (
                <ShowItem key={product.productNo} number={index} area={this.state.area} destination={this.state.destination} productNo={product.productNo} productName={product.productName} time={product.time} boat_id={product.boat_id} 
                time_end={product.time_end} areaId={product.areaId} destinationId={product.destinationId} onAddOrder={this.addOrder} today ={today} status={this.state.status} type={type}/>
            ))

        }

        
    }


    render(){
        const {voucher,cat,today,type} = this.props
        //console.log(today);
        return(
            <div>
               
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand>Booking Day Pass</Navbar.Brand>
                         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto"></Nav>
                                <Nav>
                                    <a className="btn btn-danger" href="/">Log Out</a>
                                </Nav>
                    </Navbar.Collapse>
                </Navbar>
            <div className="container-fluid ">
                <div className="row my-5">
                    <div className="col-md-6">
                        <input type="hidden" name="voucher" value={voucher}/>
                        <input type="hidden" name="cat" value={cat}/>
                        
                        {/* <h3>Travel Date </h3> <DatePicker name="travelDate" className="form-control"  dateFormat="YYYY-MM-DD" selected="" onChange={this.handleChange} value={this.state.startDate} /> */}
                    </div>
                    <div className="col-md-6 text-right">
                        <h3>Search</h3>
                        <select className="form-control my-2" style={{display: "inline-block",width: "auto"}} onChange={(e)=>this.switchArea(e.target.value)}  value={this.state.area} >
                                <option value= "select" > -- select --</option>
                                <option value= "7" >  Phuket (Rassada Pier) </option>
                                <option value= "18" > Phi Phi Island </option>
                                <option value= "10" > Krabi </option>
                                <option value= "12" > Lanta </option>
                        </select>        
                        { this.state.area === "18" &&
                            <div>
                                <select className="form-control my-2" style={{display: "inline-block",width: "auto"}} onChange={(e)=>this.switchDes(e.target.value)}  value={this.state.destination} >
                                    <option value= "select" > -- select --</option>
                                    <option value= "6" > Laemtong Beach </option>
                                    <option value= "7" > Long Beach </option>
                                    <option value= "8" > Tonsai Pier </option>
                                    <option value= "9" > Bamboo </option>
                                    <option value= "10" > Phi Phi Ley </option>
                                </select>     
                            </div>
                        }         
                       
                    </div>
                </div>
                {/* <div className="container-fluid ">
                    <div className="row">
                        <div className="col-md-12">
                <button className="mx-2 btn btn-secondary" value="1" onClick={(e)=>onSwitchCat(e.target.value)}> Boat</button>
                <button className="mx-2 btn btn-secondary" value="3" onClick={(e)=>onSwitchCat(e.target.value)}> Tour</button>
                        </div>
                    </div>
                </div> */}
               
                <div className="container-fluid ">
                    <div className="row my-5">    
                        <div className="col-md-8">
                        <h5 className="text-danger">If the seat is full, please contact the nearest service  counter</h5>    
                        <Table striped bordered hover size="sm">
                                <thead className="table-dark">
                                    <tr>
                                      
                                        <th>PRODUCT NAME</th>
                                        <th>DEPARTURE TIME</th>
                                        <th>ARRIVAL TIME</th>
                                        <th></th>
                                    </tr>
                                </thead>
                               {this.showProducts(today,type)}
                            </Table> 
                        </div>  
                        <div className="col-md-4">
                            <OrderItem  msg={this.state.msg} orders={this.state.orders} voucher={voucher} day={today} onCancelOrder={this.cancelOrder} onConfirmOrder={this.confirmOrder} onDelOrder={this.delOrder}/>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        )
    }
}

export default Product