import React , {Component} from "react";
import Axios from 'axios';

class showProduct extends Component{
    constructor(props){
        super(props);
        this.state = {allocs : "" };
    }

    

    clickAollc(productNo,classId,today){

        Axios.get("https://ipa.andamanwaveferry.com/v1/pass/getAllocation/1/"+ productNo +"/"+classId+"/"+today).then(res => {
           {this.setState({ allocs :  res.data.data})} 
         })

        //console.log(productNo);

        if(this.state.allocs){
            return  this.state.allocs && this.state.allocs.map(alloc => (
                alloc.maxPeople
            ))

        }else{
                
            return "0"
        }

       

         //const max = this.props.maxPeople;  
    }

     


    render(){
            const { productNo,productName,time,time_end,onAddOrder,area,areaId,today,status,boat_id,type,destinationId,destination } = this.props
            //console.log(this.props)
        if(area === "select" || parseInt(area) === parseInt(areaId)){
            const num = this.clickAollc(productNo,1,today)
                if(type === "NO"){
                    if(destination === "select" || parseInt(destination) === parseInt(destinationId)){
                    return(
                        <tbody>
                         <tr>
                            <td>{productName}</td>
                            <td>{time}</td> 
                            <td>{time_end}</td>
                            <td>
                            {(num > 0 && status === 0) &&
                               <button className="btn btn-block btn-info" onClick={()=>onAddOrder(this.props)}>Cart</button>
                             
                            }
                            {(num > 0 && status === 1) &&
                               <button className="btn btn-block btn-info" onClick={()=>onAddOrder(this.props)} disabled>Cart</button>
                             
                            }
                            {(num <= 0 ) &&
                                <p className="text-center">No seat</p>
                            }
                            
                            </td>
            
                         </tr>
                         </tbody>
            
                        ); 
                    }else{
                        return null;
                    }
                }else{
                    if(boat_id === 14){
                        if(destination === "select" || parseInt(destination) === parseInt(destinationId)){
                        return(
                        <tbody>
                         <tr>
                            <td>{productName}</td>
                            <td>{time}</td> 
                            <td>{time_end}</td>
                            <td>
                            {(num > 0 && status === 0) &&
                               <button className="btn btn-block btn-info" onClick={()=>onAddOrder(this.props)}>Cart</button>
                             
                            }
                            {(num > 0 && status === 1) &&
                               <button className="btn btn-block btn-info" onClick={()=>onAddOrder(this.props)} disabled>Cart</button>
                             
                            }
                            {(num <= 0 ) &&
                                <p className="text-center">No seat</p>
                            }
                            
                            </td>
            
                         </tr>
                         </tbody>
                        ); 
                    }else{
                        return null;
                    }
                    }else{
                        return null; 
                    }
                    
                }
           
        }else{
            return null;
        }
           
        }
    }

export default showProduct;